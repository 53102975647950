.App {
  background-color: var(--color-background);
}

/* Display the .main-body under the navbar */
.main-body {
  margin-top: 60px;
  /* padding: 20px; */
  min-height: calc(100vh - 100px); /* 100px is the sum of the navbar height and padding */
  height: fit-content;
}

.main-body .section {
  color: white;
  /* border: 1px solid red; */
  width: 100%;
  margin-bottom: 25px;
}

body {
  --color-primary: rgb(33, 150, 243);
  --color-secondary: rgb(38, 50, 56);

  --color-background: rgb(33, 33, 33);
  --color-background-alt: rgb(144, 164, 174);

  --color-text: rgb(255, 255, 255);

  background-color: var(--color-background); /* needed because on mobile sometimes a few white pixels will show in-between the navbar and the body */

  font-family: "Shippori Antique B1", sans-serif;
  font-weight: 400;
  font-style: normal;
}