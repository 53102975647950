.projects-content {
    min-height: calc(100vh - 120px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-content .project-list {
    /* set the max width to only allow a max of 3 cards per row */
    max-width: calc(1500px);
}

.projects-content #my-projects-text {
    font-size: clamp(1.5em, 4vw, 2.5em);
}

@media (max-width: 600px) {
    .projects-content .project-list {
        max-width: 100%;
    }
}