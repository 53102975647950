.project-card {    
    min-width: 250px;
    max-width: 400px;
    height: 300px;
    width: 90%;
    
    cursor: pointer;
    perspective: 1000px;
}

.project-card:hover {
    transform: scale(1.01);
    transition: transform 0.2s ease;
}

.project-card .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    /* transition: transform 0.6s; */
    /* animation: rotate 0.6s; */
    transform-style: preserve-3d;
}

.project-card .card-front, .project-card .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    background-color: var(--color-primary);
    border-radius: 6px;
}

.project-card .card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.project-card .card-back {
    transform: rotateY(180deg);
}

.project-card .card-back .back-content {
    width: 100%;
    height: 100%;
    /* margin-left: 20px; */
}

.project-card .card-back .text-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 100%;
}

.card-description-wrapper {
    margin-left: 15px;
    margin-right: 15px;

    display: table;
    text-align: center;
}

.project-card .card-back .text-content .card-title {
    margin-top: 15px;
    margin-bottom: 15px;
    text-decoration: underline;
    font-size: clamp(1.5rem, 2vw, 2rem);
}


.project-card .card-back .card-footer {
    position: absolute;
    bottom: 0;
    left: 0;


    height: 25%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--color-secondary);
}

.project-card .card-back .card-footer:hover {
    opacity: 0.9;
    transition: opacity 0.2s ease;
}

.footer-wrapper {
    color: var(--color-text);
    font-size: clamp(1rem, 1.5vw, 1.5rem);
}