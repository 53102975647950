.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 120px);
    height: fit-content;

    color: var(--color-text);
}

.about-content #about-header-text {
    font-size: clamp(1.5em, 4vw, 2.5em);
}

.about-content .content-container {
    width: 100%;
    height: 100%;
}

.about-content .thing-display {
    width: 100%;
    height: 100%;
    min-height: fit-content;
}

/* View Selector */
.about-content .view-selector-container {
    width: 100%;
    height: 50px;
    margin-bottom: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content .view-selector, .about-content .view-selector-text {
    list-style: none;

    height: 45px;
    min-width: 330px;
    max-width: 700px;
    width: 90%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--color-background-alt);
    border-radius: 50px;

    padding: 0 0px;
}

.about-content .view-selector li, .view-selector-text li {
    height: 100%;
    min-width: 100px;
    width: 100%;


    display: flex;
    justify-content: center;
    align-items: center;

    font-size: clamp(0.4em, 3vw, 1em);
    cursor: pointer;
    background-color: var(--color-background-alt);
    border-radius: 50px;
}


.about-content .selected {
    background-color: var(--color-primary) !important;
}

.about-content .view-selector-text {
    position: absolute;
    pointer-events: none;
    background-color: transparent;
    z-index: 9;
}

.about-content .view-selector-text li {
    background-color: transparent;
}

.active {
    display: flex !important;
}