.nav-bar {
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	height: 60px;
	width: 100vw;
	background-color: var(--color-secondary);
	/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}
@media (max-width: 550px) {
	.nav-bar .option {
		display: none;
	}
	.vert-menu-button {
		display: flex;
	}
}
@media (min-width: 550px) {
	.nav-bar .option {
		display: flex;
	}
	.vert-menu-button {
		display: none;
	}
}
.nav-bar .horizontal-nav-bar {
	list-style-type: none;
	margin: 0;
	padding-left: 15px;
	overflow: hidden;
	height: 100%;
	display: block;
}

.nav-bar .option {
	height: 100%;
	margin-right: 15px;
	float: right;
}
/* Style the options */
.nav-bar .option-content {
	display: flex;
	align-items: center;
	height: 100%;
	color: var(--color-text);
	font-size: 1em;
	text-decoration: none;
	outline: none;
}
.vert-menu-option {
	height: 100%;
	float: right;
	margin-right: 15px;
}
.vert-menu-button {
	align-items: center;
	height: 100%;
	color: var(--color-text);
	font-size: 1em;
	text-decoration: none;
	outline: none;
}
.nav-bar .vert-menu-button svg {
	height: 1.2em;
	width: 1.2em;
}
.nav-bar .hover-effect {
	cursor: pointer;
}
.nav-bar .hover-effect:hover {
	text-decoration: underline !important;
}
.nav-bar .hover-effect:active {
    transform: scale(0.95);
}
.active-option {
    color: var(--color-primary) !important;
}