.skills-thing {
    display: flex;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    height: 100%;
}

.skills-thing .side {
    width: 40%;
    min-width: fit-content;
    height: 100%;

    display: flex;
    flex-direction: column;

    /* background-color: darkred; */
}

.skills-thing .side-1 {
    align-items: end;
}

.skills-thing .side-2 {
    align-items: start;
}

.skills-thing .side .skill-bar {
    margin-bottom: 25px;
}