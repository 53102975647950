.project-list {
    display: flex;
    flex-wrap: wrap;

    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

@media (max-width: 880px) {
    /* .project-list {
        flex-direction: column;
        align-items: center;
    } */
}