.footer {
    left: 0;
    position: absolute;
    width: 100vw;
    height: 50px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    background-color: var(--color-secondary);
    color: var(--color-text);
}

.footer .middle-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-size: clamp(0.5em, 3vw, 1em);
}

.footer .middle-box .view-code {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    /* text-decoration: none; */
    /* border-bottom: 1px solid var(--color-text); */

    color: var(--color-text);
}