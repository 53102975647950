.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-content .contacts {
    display: flex;
    flex-direction: row;

    margin-top: 250px;
}

.contact-content #contact-header-text {
    font-size: clamp(1.5em, 4vw, 2.5em);
    margin-bottom: clamp(40px, 9.5vh, 100px);
}

/* Circular div  */
.contact-content .contact-item {
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #f5f5f5;
    margin: 0.5em;
}

.contact-content .contact-item:hover {
    animation: contact-item-hover 0.1s forwards;
}

@keyframes contact-item-hover {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

.contact-content .contact-item svg {
    font-size: 1.6em;
    color: black;
}


/*  */

.contact-content .info-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 150px;
}

.contact-content .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: clamp(0.9em, 2vw, 1.25em);
}

.contact-content .info-box a {
    color: var(--color-text);
}

.contact-content .info-box svg {
    font-size: clamp(2.5em, 100vw, 4em);
    color: var(--color-primary);
    margin-top: 60px;
    margin-bottom: 5px;
}