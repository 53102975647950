.home-content {
    height: calc(100vh - 60px);

    background-image: url("/public/clay-banks-oO6Gm16Cqcg-unsplash.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    resize: both;
}

.home-content .home-display {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.home-content .home-display .description-container, .home-content .home-display .head-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.home-content .sub-container {
    background-color: rgba(144, 164, 174, 0.75);
    width: 100%;
    animation: slide-in 1s ease;
}

.home-content .home-display h1 {
    font-size: clamp(1em, 9vw, 5em);
    margin-bottom: 0;
    margin-top: 20px;
    text-decoration: underline;

    /* make the text *pop* */
    /* color: var(--color-primary); */
    text-shadow: 2px 2px 4px rgba(33, 150, 243, 0.2);
}

.home-content .home-display .short-description {
    margin-bottom: 40px;

    font-size: clamp(0.7em, 2.6vw, 1.05em);
    color: #ffffffc9;

    width: 50vw;
    text-align: center;
}


.home-content .description-container {
    width: 100%;
}

.home-content .home-display p {
    margin-top: 0px;
    font-size: clamp(0.5em, 4.5vw, 1.4em);

    width: 80vw;

    text-align: center;
}