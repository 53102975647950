.skill-bar {
    max-width: 550px;
    min-width: 50px;
    width: 90%;

    padding: 10px;
}

.skill-bar .info-display {
    width: 100%;

    margin-bottom: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    color: var(--color-text);
}

.skill-bar .progress-bar {
    height: 6px;
    width: 100%;

    border-radius: 6px;
    background-color: var(--color-background-alt);
}

.skill-bar .progress-bar .progress {
    height: inherit;

    border-radius: inherit;
    background-color: var(--color-primary);
}

/* animate the progress bar loading in */
@keyframes load-progress {
    from {
        width: 0%;
    }
    to {
        max-width: width;
    }
}

.skill-bar .progress-bar .progress {
    animation: load-progress 1s ease-in;
}