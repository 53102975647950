.vert-side-bar {
    z-index: 999999;
    pointer-events: none;
    position: fixed;
    /* top: 0; */
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: end;

}

.vert-side-bar-active {
    animation: slide-out 0.5s !important;
    display: flex !important;
}

.vert-side-bar-display {
    min-width: 300px;
    height: 100%;
    z-index: 20;

    pointer-events: all;
    background-color: var(--color-secondary);

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.vert-side-bar .vert-items {
    list-style: none;
    display: flex;
    flex-direction: column;
}

.vert-side-bar .vert-option {
    color: var(--color-text);
    margin-top: 15px;
}

.vert-side-bar .vert-option-content {
    color: var(--color-text);
    text-decoration: none;
    cursor: pointer;
    font-size: 1.5em;
}

.vert-side-bar .vert-option-content:hover {
    text-decoration: underline;
}